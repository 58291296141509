<template>
  <div>
    <ManageAssistantTeacherBar/>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-flow"
        :title="
          'ข้อมูลเลขที่ตำแหน่ง วิทยาลัย ใช้รายงานตัว รอบที่ : ' +
            periodassteachs.periodAssTeachTimes +
            ' / ' +
            periodassteachs.periodAssTeachYear
        "
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" md="8" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" md="4" class="text-right">
              <v-btn
                block
                right
                depressed
                color="primary"
                @click.native="manage_college_manpowerAdd()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
              </v-btn>

              <v-btn
                block
                right
                depressed
                color="primary"
                @click.native="manage_college_manpowerCSV()"
              >
                <v-icon>mdi-file-import</v-icon>นำเข้าข้อมูลอัตรารับย้าย
              </v-btn>

              <v-btn
                block
                right
                depressed
                color="info"
                @click.native="manage_college_manpowerTotal()"
              >
                <v-icon>mdi-clipboard-text</v-icon>สรุปจำนวนเรียกรายงานตัว
              </v-btn>
              <v-btn
                block
                right
                depressed
                color="info"
                @click.native="manage_college_manpowerQueryAll()"
              >
                <v-icon>mdi-clipboard-text</v-icon>แสดงข้อมูลทั้งหมด
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="manage_college_manpowers"
          :search="search"
          group-by="mcm_id_branch"
          class="elevation-1"
        >
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="10">
              <v-icon @click="toggle"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              {{ items[0].mcm_id_branch + " : " + items[0].name_branch }}
            </th>
          </template>

          <template v-slot:item.whoBooking="{ item }">
            <span>{{ item.whoBooking }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
            v-if="
                item.mcm_status !== 'send' &&
                  item.mcm_status !== 'receipt'
              "
              color="warning"
              @click.stop="
                manage_college_manpowerEdit(
                  item.mcm_times,
                  item.mcm_years,
                  item.mcm_id_position
                )
              "
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon
              v-if="
                item.mcm_status !== 'send' &&
                  item.mcm_status !== 'receipt'
              "
              color="red"
              @click.stop="
                manage_college_manpowerDelete(
                  item.mcm_times,
                  item.mcm_years,
                  item.mcm_id_position
                )
              "
            >
              mdi-delete
            </v-icon>
            <v-icon v-else dark color="warning">mdi-cube-send</v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--addmanage_college_manpowerdialog  -->
      <v-layout row justify-center>
        <v-dialog
          v-model="addmanage_college_manpowerdialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              :title="
                'เพิ่มข้อมูลเลขที่ตำแหน่งวิทยาลัยใช้รายงานตัว รอบที่ : ' +
                  periodassteachs.periodAssTeachTimes +
                  ' / ' +
                  periodassteachs.periodAssTeachYear
              "
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="addmanage_college_manpowerform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md4>
                      <v-autocomplete
                        :items="colleges"
                        item-text="college_name"
                        item-value="college_code"
                        outlined
                        label="วิทยาลัย"
                        v-model="addmanage_college_manpower.mcm_college_code"
                        required
                        :rules="[v => !!v || '']"
                        @change="jor18Query(), CollegeMPQuery()"
                      >
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        :items="jor18s"
                        item-text="id_position"
                        item-value="id_position"
                        outlined
                        label="เลขที่ตำแหน่ง"
                        v-model="addmanage_college_manpower.mcm_id_position"
                        required
                        :rules="[v => !!v || '']"
                      >
                        <template v-slot:item="{ item }">
                          <div class="pa-2">
                            <div class="font-weight-black">
                              {{ item.college }}
                            </div>

                            <div class="divTab50">
                              {{ item.id_position }} :
                              {{ item.id_card || "ว่าง" }}
                            </div>
                          </div>
                        </template>
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        :items="branchs"
                        item-text="name_branch"
                        item-value="id_branch"
                        outlined
                        label="สาขาวิชา"
                        v-model="addmanage_college_manpower.mcm_id_branch"
                        required
                        :rules="[v => !!v || '']"
                      >
                        <template v-slot:item="{ item }">
                          <div class="pa-2">
                            <div class="divTab50">
                              {{ item.id_branch }} :
                              {{ item.name_branch }}
                            </div>
                          </div>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <v-flex md12>
                      <v-data-table
                        color="success"
                        :loading="loading"
                        :headers="headersCollegeBranch"
                        :items="CollegeMPs"
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-icon
                            color="red"
                            @click.stop="
                              manage_college_manpowerDelete(
                                item.mcm_times,
                                item.mcm_years,
                                item.mcm_id_position
                              )
                            "
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                color="warning"
                large
                @click.stop="addmanage_college_manpowerdialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="addmanage_college_manpowerSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deletemanage_college_manpowerdialog -->
      <v-layout>
        <v-dialog
          v-model="deletemanage_college_manpowerdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบรายการเลขที่ตำแหน่ง รับรายงานตัว"
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form
                    ref="deletemanage_college_manpowerform"
                    lazy-validation
                  >
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูล <br />
                          {{
                            editmanage_college_manpower.mcm_times +
                              "/" +
                              editmanage_college_manpower.mcm_years +
                              " : " +
                              editmanage_college_manpower.college_name +
                              " เลขที่ตำแหน่ง " +
                              editmanage_college_manpower.mcm_id_position
                          }}
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="deletemanage_college_manpowerdialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deletemanage_college_manpowerSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editmanage_college_manpowerdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editmanage_college_manpowerdialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูล เลขที่ตำแหน่งรายงานตัว"
              class="px-5 py-3"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editmanage_college_manpowerform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md4>
                      <h3>{{ editmanage_college_manpower.college_name }}</h3>
                    </v-flex>
                    <v-flex md3>
                      <h3>
                        เลขที่ตำแหน่ง :
                        {{ editmanage_college_manpower.mcm_id_position }}
                      </h3>
                    </v-flex>

                    <v-flex md5>
                      <v-autocomplete
                        :items="branchs"
                        item-text="name_branch"
                        item-value="id_branch"
                        outlined
                        label="สาขาวิชา"
                        v-model="editmanage_college_manpower.mcm_id_branch"
                        required
                        :rules="[v => !!v || '']"
                      >
                        <template v-slot:item="{ item }">
                          <div class="pa-2">
                            <div class="divTab50">
                              {{ item.id_branch }} :
                              {{ item.name_branch }}
                            </div>
                          </div>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <v-flex md12>
                      <v-data-table
                        color="success"
                        :loading="loading"
                        :headers="headersCollegeBranch"
                        :items="CollegeMPs"
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-icon
                            color="info"
                            @click.stop="
                              mamCollegeQuery(
                                item.mcm_times,
                                item.mcm_years,
                                item.mcm_college_code
                              )
                            "
                          >
                            mdi-account-search
                          </v-icon>
                        </template>
                      </v-data-table>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="editmanage_college_manpowerdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editmanage_college_manpowerSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model manage_college_manpowerCSVdialog -->
      <v-layout>
        <v-dialog
          v-model="manage_college_manpowerCSVdialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              title="นำเข้าข้อมูลเลขที่ตำแหน่ง"
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-card-text>
                  <v-form
                    ref="manage_college_manpowerCSVdialogform"
                    lazy-validation
                  >
                    <v-container grid-list-md>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-btn
                            outlined
                            block
                            large
                            color="info"
                            to="/admin/manage_college_manpower_import/"
                            target="_blank"
                          >
                            <v-icon>mdi-exit-to-app</v-icon
                            >ส่งออกแบบฟอร์มบันทึกข้อมูล</v-btn
                          >
                        </v-col>
                        <v-col cols="12" md="12">
                          <h2>
                            <v-file-input
                              v-model="file2"
                              accept=".csv"
                              name="csvFiles"
                              color="deep-purple accent-4"
                              counter
                              label="ไฟล์ .csv"
                              placeholder="ไฟล์ CSV นำเข้าข้อมูลเลขที่ตำแหน่ง ครูผู้ช่วย"
                              prepend-icon="mdi-paperclip"
                              outlined
                              rounded
                              :show-size="1000"
                            >
                              <template v-slot:selection="{ index, text }">
                                <v-chip
                                  v-if="index < 2"
                                  color="deep-purple accent-4"
                                  dark
                                  label
                                  small
                                >
                                  {{ text }}
                                </v-chip>

                                <span
                                  v-else-if="index === 2"
                                  class="text-overline grey--text text--darken-3 mx-2"
                                >
                                  +{{ files.length - 2 }} File(s)
                                </span>
                              </template>
                            </v-file-input>
                          </h2>

                          <v-btn
                            class="pa-2"
                            block
                            large
                            color="green"
                            @click.stop="csvManpowerSubmit()"
                          >
                            <v-icon>mdi-book-plus</v-icon>
                            นำเข้า</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="grey"
                  @click.stop="manage_college_manpowerCSVdialog = false"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model manage_college_manpowerCountDialog -->
      <v-layout>
        <v-dialog
          v-model="manage_college_manpowerCountDialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              title="ข้อมูลสรุปเลขที่ตำแหน่ง รับรายงานตัว"
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                  single-line
                  hide-details
                  dense
                  filled
                  class="mb-2"
                />

                <v-data-table
                  color="success"
                  :loading="loading"
                  :headers="headersCount"
                  :items="manage_college_manpowerCount"
                  :search="search"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      color="info"
                      @click.stop="
                        mamCollegeQuery(
                          item.mcm_times,
                          item.mcm_years,
                          item.mcm_college_code
                        )
                      "
                    >
                      mdi-account-search
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="grey"
                  @click.stop="manage_college_manpowerCountDialog = false"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import ManageAssistantTeacherBar from '../../../components/admin/manageAssistantTeacherBar.vue';

export default {
    name: "HRvecManageAssistantTeacher",
    data() {
        return {
            loading: true,
            ApiKey: "HRvec2021",
            valid: true,
            file2: null,
            addmanage_college_manpowerdialog: false,
            editmanage_college_manpowerdialog: false,
            deletemanage_college_manpowerdialog: false,
            manage_college_manpowerCSVdialog: false,
            manage_college_manpowerCountDialog: false,
            snackbar: {
                show: false,
                color: "",
                timeout: 5000,
                icon: "",
                text: ""
            },
            manage_college_manpowers: [],
            addmanage_college_manpower: {},
            editmanage_college_manpower: {},
            userstatus: [],
            search: "",
            pagination: {},
            headers: [
                { text: "รอบที่", align: "left", value: "mcm_times" },
                { text: "ปี", align: "left", value: "mcm_years" },
                { text: "ภาค", align: "left", value: "region_name" },
                { text: "จังหวัด", align: "left", value: "province_name" },
                { text: "วิทยาลัย", align: "left", value: "college_name" },
                { text: "เลขที่ตำแหน่ง", align: "left", value: "mcm_id_position" },
                { text: "สาขาวิชา", align: "left", value: "mcm_id_branch" },
                { text: "สาขาวิชา", align: "left", value: "name_branch" },
                { text: "สถานะ", align: "left", value: "whoBooking" },
                {
                    text: "แก้ไข",
                    align: "center",
                    value: "actions",
                    icon: "mdi-file-document-edit"
                },
                {
                    text: "ลบ",
                    align: "center",
                    value: "action_s",
                    icon: "mdi-delete-forever"
                }
            ],
            rowsperpage: [
                25,
                50,
                100,
                {
                    text: "All",
                    value: -1
                }
            ],
            college: {},
            provinces: [],
            prefectures: [],
            collgegs: [],
            manage_college_manpowerstatus: [],
            regions: [],
            region_ena: true,
            data_syslog: {},
            branchs: [],
            periodassteachs: [],
            headersCount: [
                { text: "รอบที่", align: "left", value: "mcm_times" },
                { text: "ปี", align: "left", value: "mcm_years" },
                { text: "รหัส", align: "left", value: "mcm_college_code" },
                { text: "วิทยาลัย", align: "left", value: "college_name" },
                { text: "จำนวน", align: "left", value: "collegeGroup" },
                { text: "แสดง", align: "left", value: "actions" }
            ],
            headersCollegeBranch: [
                { text: "รอบที่", align: "left", value: "mcm_times" },
                { text: "ปี", align: "left", value: "mcm_years" },
                { text: "วิทยาลัย", align: "left", value: "college_name" },
                { text: "เลขที่ตำแหน่ง", align: "left", value: "mcm_id_position" },
                { text: "สาขาวิชา", align: "left", value: "mcm_id_branch" },
                { text: "สาขาวิชา", align: "left", value: "name_branch" },
                {
                    text: "ลบ",
                    align: "center",
                    value: "actions",
                    icon: "mdi-delete-forever"
                }
            ],
            manage_college_manpowerCount: [],
            colleges: [],
            jor18s: [],
            CollegeMPs: []
        };
    },
    async mounted() {
        await this.periodassteachQuery();
        await this.branchQuery();
        await this.mcmCountQuery();
        await this.manage_college_manpowerQueryAll();
        await this.collegeQuery();
    },
    methods: {
        async collegeQuery() {
            let result = await this.$http.post("college.php", {
                ApiKey: this.ApiKey
            });
            this.colleges = result.data;
        },
        async jor18Query() {
            let result = await this.$http.post("jor18.php", {
                ApiKey: this.ApiKey,
                j18college_code: this.addmanage_college_manpower.mcm_college_code
            });
            this.jor18s = result.data;
        },
        async mamCollegeQuery(mcm_times, mcm_years, mcm_college_code) {
            let result = await this.$http.post("manage_college_manpower.php", {
                ApiKey: this.ApiKey,
                mcm_times: mcm_times,
                mcm_years: mcm_years,
                mcm_college_code: mcm_college_code
            });
            this.manage_college_manpowers = result.data;
            this.manage_college_manpowerCountDialog = false;
        },
        async CollegeMPQuery() {
            let result = await this.$http.post("manage_college_manpower.php", {
                ApiKey: this.ApiKey,
                mcm_times: this.periodassteachs.periodAssTeachTimes,
                mcm_years: this.periodassteachs.periodAssTeachYear,
                mcm_college_code: this.addmanage_college_manpower.mcm_college_code
            });
            this.CollegeMPs = result.data;
        },
        async mcmCountQuery() {
            let result = await this.$http.post("manage_college_manpower.php", {
                ApiKey: this.ApiKey,
                mcm_times: this.periodassteachs.periodAssTeachTimes,
                mcm_years: this.periodassteachs.periodAssTeachYear,
                mcm_count: "Ok"
            });
            this.manage_college_manpowerCount = result.data;
        },
        async periodassteachQuery() {
            let result = await this.$http.post("periodassteach.php", {
                ApiKey: this.ApiKey,
                periodAssTeachEnablePro: "1"
            });
            this.periodassteachs = result.data;
        },
        async branchQuery() {
            let result = await this.$http.post("branch.php", {
                ApiKey: this.ApiKey
            });
            this.branchs = result.data;
        },
        async csvManpowerSubmit() {
            let result = "";
            let uploaded = null;
            if (this.file2) {
                let formData = new FormData();
                let filename = this.time_stamp + ".manpower.csv";
                formData.append("file", this.file2);
                formData.append("filename", "../HRvecfiles/" + filename);
                formData.append("ApiKey", this.ApiKey);
                result = await this.$http.post("manage_college_manpowerCSV.php", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });
                uploaded = true;
            }
            else {
                uploaded = false;
            }
            if (result.data.status == true) {
                Swal.fire({
                    icon: "success",
                    title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
                    showConfirmButton: false,
                    timer: 1500
                });
                this.manage_college_manpowerQueryAll();
            }
            else {
                Swal.fire({
                    icon: "warning",
                    title: "ดำเนินการผิดพลาด",
                    showConfirmButton: false,
                    timer: 1500
                });
                this.manage_college_manpowerQueryAll();
            }
            this.manage_college_manpowerCSVdialog = false;
        },
        async manage_college_manpowerCSV() {
            this.manage_college_manpowerCSVdialog = true;
        },
        async manage_college_manpowerTotal() {
            await this.mcmCountQuery();
            this.manage_college_manpowerCountDialog = true;
        },
        async sweetAlertLoading() {
            let timerInterval;
            Swal.fire({
                title: "Auto close alert!",
                html: "I will close in <b></b> milliseconds.",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                },
                willClose: () => {
                    clearInterval(timerInterval);
                }
            }).then(result => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                    
                }
            });
        },
        async manage_college_manpowerReplace() {
            this.loading = true;
            let result = await this.$http
                .post("manage_college_manpower.php", {
                ApiKey: this.ApiKey,
                manage_college_manpowerCheck: "Ok"
            })
                .finally(() => (this.loading = false));
            this.manage_college_manpowers = result.data;
        },
        async manage_college_manpowerQueryAll() {
            this.loading = true;
            let result = await this.$http
                .post("manage_college_manpower.php", {
                ApiKey: this.ApiKey,
                mcm_times: this.periodassteachs.periodAssTeachTimes,
                mcm_years: this.periodassteachs.periodAssTeachYear
            })
                .finally(() => (this.loading = false));
            this.manage_college_manpowers = result.data;
        },
        async manage_college_manpowerBranchIDQueryAll() {
            this.loading = true;
            let result = await this.$http
                .post("manage_college_manpower.php", {
                ApiKey: this.ApiKey,
                mcm_id_branch: this.ApiKey
            })
                .finally(() => (this.loading = false));
            this.manage_college_manpowers = result.data;
        },
        async manage_college_manpowerAdd() {
            this.addmanage_college_manpower = {};
            this.CollegeMPs = [];
            this.addmanage_college_manpowerdialog = true;
        },
        async addmanage_college_manpowerSubmit() {
            if (this.$refs.addmanage_college_manpowerform.validate()) {
                this.addmanage_college_manpower.ApiKey = this.ApiKey;
                this.addmanage_college_manpower.mcm_times = this.periodassteachs.periodAssTeachTimes;
                this.addmanage_college_manpower.mcm_years = this.periodassteachs.periodAssTeachYear;
                this.addmanage_college_manpower.mcm_id_card_booking = "";
                this.addmanage_college_manpower.mcm_status = "";
                let result = await this.$http.post("manage_college_manpower.insert.php", this.addmanage_college_manpower);
                if (result.data.status == true) {
                    this.manage_college_manpower = result.data;
                    Swal.fire({
                        icon: "success",
                        title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                else {
                    Swal.fire({
                        icon: "warning",
                        title: "ดำเนินการบันทึกข้อมูลผิดพลาด",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                this.manage_college_manpowerQueryAll();
                this.CollegeMPQuery();
            }
        },
        async manage_college_manpowerEdit(mcm_times, mcm_years, mcm_id_position) {
            let result = await this.$http.post("manage_college_manpower.php", {
                ApiKey: this.ApiKey,
                mcm_times: mcm_times,
                mcm_years: mcm_years,
                mcm_id_position: mcm_id_position
            });
            this.editmanage_college_manpower = result.data;
            let resultmcm = await this.$http.post("manage_college_manpower.php", {
                ApiKey: this.ApiKey,
                mcm_times: this.editmanage_college_manpower.mcm_times,
                mcm_years: this.editmanage_college_manpower.mcm_years,
                mcm_college_code: this.editmanage_college_manpower.mcm_college_code
            });
            this.CollegeMPs = resultmcm.data;
            this.editmanage_college_manpowerdialog = true;
        },
        async editmanage_college_manpowerSubmit() {
            if (this.$refs.editmanage_college_manpowerform.validate()) {
                this.editmanage_college_manpower.ApiKey = this.ApiKey;
                let result = await this.$http.post("manage_college_manpower.update.php", this.editmanage_college_manpower);
                if (result.data.status == true) {
                    this.manage_college_manpower = result.data;
                    Swal.fire({
                        icon: "success",
                        title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    this.manage_college_manpowerQueryAll();
                }
                else {
                    Swal.fire({
                        icon: "warning",
                        title: "ดำเนินการผิดพลาด",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                let resultmcm = await this.$http.post("manage_college_manpower.php", {
                    ApiKey: this.ApiKey,
                    mcm_times: this.editmanage_college_manpower.mcm_times,
                    mcm_years: this.editmanage_college_manpower.mcm_years,
                    mcm_college_code: this.editmanage_college_manpower.mcm_college_code
                });
                this.CollegeMPs = resultmcm.data;
            }
        },
        async manage_college_manpowerDelete(mcm_times, mcm_years, mcm_id_position) {
            let result = await this.$http.post("manage_college_manpower.php", {
                ApiKey: this.ApiKey,
                mcm_times: mcm_times,
                mcm_years: mcm_years,
                mcm_id_position: mcm_id_position
            });
            this.editmanage_college_manpower = result.data;
            this.deletemanage_college_manpowerdialog = true;
        },
        async deletemanage_college_manpowerSubmit() {
            if (this.$refs.deletemanage_college_manpowerform.validate()) {
                this.editmanage_college_manpower.ApiKey = this.ApiKey;
                let result = await this.$http.post("manage_college_manpower.delete.php", this.editmanage_college_manpower);
                if (result.data.status == true) {
                    this.manage_college_manpower = result.data;
                    Swal.fire({
                        icon: "success",
                        title: "ดำเนินการข้อมูลเรียบร้อย",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                else {
                    Swal.fire({
                        icon: "warning",
                        title: "ดำเนินการผิดพลาด",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                this.manage_college_manpowerQueryAll();
                this.deletemanage_college_manpowerdialog = false;
                this.CollegeMPQuery();
            }
        }
    },
    computed: {
        color() {
            return "light-blue-darken-4";
        },
        pages() {
            if (this.pagination.rowsPerPage == null ||
                this.pagination.totalItems == null)
                return 0;
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
        },
        date_today_log() {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, "0");
            let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            let yyyy = today.getFullYear() + 543;
            let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            today = dd + "/" + mm + "/" + yyyy + "/" + time;
            return today;
        }
    },
    components: { ManageAssistantTeacherBar }
};
</script>

<style lang="scss" scoped></style>
